import { Environment } from '../app/shared/models/environment';

export const environment: Environment = {
  production: true,
  name: 'dev',
  baseUrl: 'https://sunleon-dev.virya-energy.fr/sunleon-api-rest',
  oauth: {
    clientId: '1fkr9u3pfq6so2khkcvr2d82gs',
    loginUrl: 'https://sunleon-dev-user-pool-app.auth.eu-west-3.amazoncognito.com/oauth2/authorize',
    issuer: 'https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_6XBulpzDA',
    logoutUrl: 'https://sunleon-dev-user-pool-app.auth.eu-west-3.amazoncognito.com/logout',
    tokenEndpoint: 'https://sunleon-dev-user-pool-app.auth.eu-west-3.amazoncognito.com/oauth2/token',
    userInfoEndpoint: 'https://sunleon-dev-user-pool-app.auth.eu-west-3.amazoncognito.com/oauth2/userInfo',
    jwks: {
      keys: [
        {
          alg: 'RS256',
          e: 'AQAB',
          kid: '7n54yhK/X7UNRMgFsAwSebG6cM2sEwlAtxOLQigUCz4=',
          kty: 'RSA',
          n: 't_oGt6NZdKNbhyc9kV17IYlAsCIW2V1VjEoWjfHgcYxriBLTnrqL4OUb25jV363XSm4Ai4dEBtLDCtI0496Dktsf-ujD73R9sTJ3N2RX7rNe-P2Kp-4-GMFgn8HfqOjk7ejgJhS9ECLgTgtWjHNo7zdK8ehlERi_O619z4DVhTlp5-APqxsiNl08E8C6ZHoMPhCBKQI_ayi2JglNBKkrjl3HrxjcwJH_H_LD_Vmk_Hh16kwssYhyiSSMJvjTc_O4iGYgODSHC2z3ytOHsHYCAhMC7IvRaMwHPbnh5C-JtDrv-VODQS8o87Y0ZR8jQI7oxedhyv3nT_dyFefLMssojQ',
          use: 'sig',
        },
        {
          alg: 'RS256',
          e: 'AQAB',
          kid: 'pOGW73iR3+ihNdmrMra7njPzoygr+zeG6MItFnkIzaE=',
          kty: 'RSA',
          n: 'vkv--41ejmxu9XlTzFE_Dcv38BAqkkQGn1mkJD_zwnWh1mdBaSUyf0hnkkCqNLCDtXTkkI4TUkRZYTi9KlemmEi8Y5FDWK1PEFtJ3R0IEx0VYbBRH6m4yJ6g_TIl18PfnW5RS4SKlKVDQLQcA_yEbar9SvDK0Xd4qGoWIV4Vhe_DRVqz_SdxYUstl8ltM2aRv-XJFxBGiuMDj2UnW6JYacCz3HBR-Z9ZYqZFPkqTIWeEpxNH2J_6Re1S5A3HXiG7r2q-aluTqv3uE0HwKNe82L15g8yvciSmSWoejlQMlJRsNY-l-OFeTMnPwwYJTOaCjVT50sFekIc_RUBUvjn3cQ',
          use: 'sig',
        },
      ],
    },
  },
}
