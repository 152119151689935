<nav class="navbar-main fixed-top shadow-sm">
    <div class="row">
      <div class="col-4 col-md-2">
        <img src="../../../../assets/images/logos/logo-sunopee-endossement.png" alt="" height="60">
      </div>
      <div class="col-4 col-md-8 d-flex justify-content-md-center align-items-center">
        <div class="environnement-texte">{{envtest}}</div>
        <a class="navbar-brand mx-auto"
           href="/">
          SUNLEON
        </a>
        <a class="lien-parametre bi bi-gear-fill" title='Paramètres' [routerLink]="['/parametres/famille/', famille]" *ngIf="user.administrateur"></a>
      </div>
      <div class="col-4 col-md-2 d-flex justify-content-center align-items-center" *ngIf="user">
          <ul class="navbar-nav">
            <li class="nav-item" ngbDropdown container="body">
              <div class="user-pp" ngbDropdownToggle>{{user.getInitiales()}}</div>
              <div ngbDropdownMenu class="dropdown-menu">
                <button ngbDropdownItem (click)="authService.logOut()">Se déconnecter</button>
              </div>
            </li>
          </ul>
        </div>
    </div>
</nav>
